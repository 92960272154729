import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import {
  ForCard,
  FooterRecCard,
  IndustriesStatic,
  IconRow,
} from "../components/resources"
import Dar from "@mui/icons-material/DoubleArrow"

import Layout from "../components/layout"
import Seo from "../components/seo"

const homeIcon = [
  {
    title: "BUSINESS CONTINUITY",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Success-Story.pdf",
    rec: "../images/landing/icon6.png",
    content:
      "Because we collect the most data AND because we have omni-dimensional analysis, we provide the lowest mean time from detect to contain to remediate.",
  },
  {
    title: "INTELLIGENCE FLOW",
    LinkUr: "/the-platform/",
    rec: "../images/landing/icon7.png",
    content:
      "Through our 24/7 Visibility Platform, on-shore analysts and constant reporting, you will always know what is happening, why and how your security is ever-improving.",
  },
  {
    title: "TIME PROTECTION",
    LinkUr: "/blog-pages/breach-response",
    rec: "../images/landing/icon8.png",
    content:
      "We collect more, analyze more and bother you less. We ensure that every Breach Response is precise, valid and actionable. Unlimited Breach Promise.",
  },
]

const iconRow = [
  {
    imgSrc: "../images/icons/1-icon.png",
    title: "PRECISION",
    content: "Our Curated and Designed Source Content is Simply the Best",
  },
  {
    imgSrc: "../images/icons/2-icon.png",
    title: "COLLABORATION",
    content: "The Vigilant Hunt Team is Committed to Your Cause",
  },
  {
    imgSrc: "../images/icons/3-icon.png",
    title: "US BASED",
    content: "Vigilant Never Relies on Off-shore Teams",
  },
  {
    imgSrc: "../images/icons/4-icon.png",
    title: "RADICAL VISIBILITY ",
    content: "Vigilant Provides Assurance Through Transparency",
  },
  {
    imgSrc: "../images/icons/5-icon.png",
    title: "UNLIMITED BREACH RESPONSE",
    content:
      "No Retainers or Additional Fees – Our Warranty is Unmatched in the Industry",
  },
]

const resourcesList = [
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMNDR",
    subtitle: " Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const IndexPage = () => (
  <Layout>
    <Seo title="Cyber Security &amp; Network Protection Company" />

    <section className="section-hero-home">
      <div className="container">
        <div className="row">
          <div className="buffer">
            <h1>
              WELCOME TO <br />
              THE HIGH GROUND
            </h1>
            <p className="lead">
              Vigilant’s elite team of Managed Detection & Response experts
              enables you to combat every cyber-threat with omnidirectional
              visibility. Master the Art of Cyber-War and take back the
              advantage.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  START NOW
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </Link>
          </div>
          <div className="section-col-2">
            <div className="feature-box--home">
              <Link to="/secure-the-high-ground/">
                <ul className="feature-box--link">
                  <li className="feature-box--img">
                    <StaticImage
                      src="../images/landing/Vigilant_HigherGroundCover-for-Web.png"
                      quality={95}
                      formats={["AUTO", "WEBP"]}
                      layout="fullWidth"
                      alt="8 Questions"
                      className="img-fluid"
                    />
                  </li>
                  <li className="feature-box--title"></li>
                  <li className="feature-box--cta">
                    <span className="readNow">READ NOW </span>
                    <i className="block-chase-icon"></i>
                  </li>
                </ul>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-col-2-img">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h2>YOUR PERPETUAL ANALYSIS TEAM</h2>
            <h6>DETECT. ANALYZE. PRESENT.</h6>
            <p>
              We ingest live data across your integration points including – but
              not limited to – network, endpoints and email. Then we run this
              collected data through our Adaptive Intelligence Process™ to
              detect, analyze and provide intel to you and your team. Using this
              collaborative process approach allows us to confirm threats faster
              and pivot quickly to ensure your environment is safe from
              attackers.
            </p>
          </div>
          <div className="section-col col-sm-12 pt-5">
            <StaticImage
              src="../images/bg/infographic-home.svg"
              formats={["png"]}
            />
          </div>
        </div>
      </div>
    </section>
    <div>
      <section className="section-title--home-link grid--bg">
        <div className="container">
          <div className="row">
            <div className="section-col-1 ">
              <hr />
              <h3>KNOW YOUR ENEMY AND KNOW YOURSELF</h3>
              <h6>TAKE THE SECURITY HIGH GROUND</h6>
              <p>
                Threats are coming from everywhere, searching out the slightest
                cracks in your outer defenses, even sneaking inside, silently
                hiding, waiting to execute ransomware or exfiltrate your data.
                You cannot fight what you cannot see.
              </p>
              <p className="lead">
                “I’M PROUD THAT WE LITERALLY PROTECT PEOPLE FROM THE WORST THING
                IMAGINABLE.”
              </p>
              <p className="sm-text">- Paul Schaefer, Vigilant COO</p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    LEARN MORE
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2">
              <div className="feature-box--for-security">
                <Link to="/secure-the-high-ground/">
                  <ul className="feature-box--link">
                    <li className="feature-box--img">
                      <StaticImage
                        src="../images/landing/Vigilant_HigherGroundCover-for-Web.png"
                        quality={95}
                        formats={["AUTO", "WEBP"]}
                        layout="fullWidth"
                        alt="8 Questions"
                        className="img-fluid"
                      />
                    </li>
                    <li className="feature-box--title"></li>
                    <li className="feature-box--cta">
                      <span className="readNow">READ NOW </span>
                      <i className="block-chase-icon"></i>
                    </li>
                  </ul>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section className="section-features">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h3>APPROACH</h3>
            </div>
          </div>
          <div className="feat-box">
            <ul className="section-5-icons--list">
              {iconRow.map(data => {
                return (
                  <IconRow
                    iconImg={data.imgSrc}
                    iconTitle={data.title}
                    iconContent={data.content}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </section>

      <section className="section-cards-3--for-card">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <section className="section-features">
                <div className="section-title">
                  <h3>OUTCOME</h3>
                </div>
              </section>
              <ul className="card-feature-list">
                {homeIcon.map(data => {
                  return (
                    <ForCard
                      altTxt={data.title}
                      title={data.title}
                      imgSrc={data.rec}
                      content={data.content}
                      LinkUrl={data.LinkUr}
                      key={uuidv4()}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="grid--bg-cyberdna">
      <section className="section-platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="side-binder">
                <h6>The Platform</h6>
                <h3>CyberDNA®</h3>
              </div>
              <p className="p-quote">
                “On open ground, I would keep a vigilant eye on my defenses.”
              </p>
              <p className="p-quote--author">Art of War</p>
              <p className="content">
                Through our collaborative CyberDNA® Platform, real-time
                communications, battle simulations and remediations are
                essential. They are the living embodiment of our Never Quit,
                Fight to Defend and Take Responsibility relationship with every
                client. The result is a trusted partnership in cybersecurity.
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    GET STARTED NOW
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="col-sm-6 offset-sm-1 pt-5">
              <StaticImage
                src="../images/platform/tabUpdatedPlatform.png"
                quality={95}
                formats={["png"]}
                alt="Vigilant Tablet"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-basic-h-sub">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>VIGILANT’S MANAGED DETECTION AND RESPONSE</h3>
              <h6>THE ART OF CYBER WAR</h6>

              <p className="p-quote pt-5">
                “Always have the HIGH GROUND when facing your opponent.”
              </p>
              <p>
                Managed Detection and Response (MDR) has become a household term
                among security professionals, but it can have a different
                definition depending upon who you talk to. One thing is clear:
                VigilantMDR combines battle-tested human analysis with advanced
                technologies that enable companies to become resistant to the
                impact of attacks. We’re so confident in our approach to
                VigilantMDR services that we back them with our Unlimited Breach
                Response warranty at no additional cost.
              </p>
              <p>
                Our Managed Detection and Response is also seamless, integrated,
                continuously searching and gathering intel. And the data output
                of each technology is monitored and analyzed by a dedicated team
                of analysts, engineers and remediation specialists. These expert
                threat hunters are the steadfast sentinels that protect you and
                your company’s precious assets. Vigilant also stands behind our
                promise of Unlimited Breach Response. It’s why we keep a secure
                24/7/365 US based SOC. And for added peace of mind, you can rest
                assured that Vigilant will never sell your data or reveal your
                identity. Ever.
              </p>
            </div>
            <div className="section-col-2">
              <p className="p-quote">
                <span className="p-quote--author"></span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-col-4">
      <div className="container">
        <div className="row">
          <div className="section-col-4--card">
            <h6>NETWORK VISIBILITY</h6>
            <p>
              Gain insight to what your devices are doing. Remove the need to
              guess and dispel false positives with advanced meta-analysis,
              threat instantiation and continuous network forensics.
            </p>
            <Link to="/mdr-services/">LEARN MORE </Link>
          </div>
          <div className="section-col-4--card">
            <h6>ENDPOINT VISIBILITY</h6>
            <p>
              Gain deep insight into your endpoints through curated telemetry
              data combined with Vigilant’s MEDR service that stops threats
              before they start.
            </p>
            <Link to="/mdr-services/">LEARN MORE </Link>
          </div>
          <div className="section-col-4--card">
            <h6>EMAIL VISIBILITY</h6>
            <p>
              Identify Office account takeovers and detect attacks with curated
              detection while clean and concise dashboards give you awareness on
              your Microsoft365 user environment.
            </p>
            <Link to="/mdr-services/">LEARN MORE</Link>
          </div>
          <div className="section-col-4--card">
            <h6>
              UNLIMITED BREACH <br />
              RESPONSE COVERAGE
            </h6>
            <p>
              Vigilant is the only cybersecurity company to back up its services
              by providing Unlimited Breach Response in the event of a breach.
            </p>
            <Link to="/blog-pages/breach-response">LEARN MORE</Link>
          </div>
          <p className="text-center ">
            <em>
              “WE PUT HUMANS BACK IN THE GAME” -CHRIS NYHUIS, VIGILANT CEO
            </em>
          </p>
        </div>
      </div>
    </section>
    <div className="home-bg-object-2">
      <section className="section-blocks-6">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>INDUSTRIES</h3>
            </div>
            <div className="section-col-2">
              <IndustriesStatic />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section className="section-client-cards">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>HEAR FROM OUR CLIENTS</h3>

              <ul className="blocks-clients">
                <li>
                  <a
                    href="https://sp1.sdcdn.app/temp/Vigilant_MDR_SuccessStory.pdf"
                    target="__blank"
                  >
                    <ul className="block-link-card">
                      <li className="block-img">
                        <StaticImage
                          src="../images/landing/home_clients_01.jpg"
                          quality={95}
                          formats={["AUTO", "WEBP"]}
                          layout="fullWidth"
                          alt="Large Manufacturing"
                          className="img-fluid"
                        />
                      </li>
                      <li className="block-title">
                        LARGE MANUFACTURING COMPANY
                      </li>
                      <li className="block-content">
                        See how Vigilant is detecting potential email compromise
                        while protecting all of the lumber company’s endpoints
                        from advanced attacks.
                      </li>
                      <li className="block-read-more">
                        <p>
                          Read More <i className="block-chase-icon"></i>
                        </p>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a
                    href="https://sp1.sdcdn.app/temp/V365_SuccessStory.pdf"
                    target="__blank"
                  >
                    <ul className="block-link-card">
                      <li className="block-img">
                        <StaticImage
                          src="../images/landing/home_clients_02.jpg"
                          quality={95}
                          formats={["AUTO", "WEBP"]}
                          layout="fullWidth"
                          alt="Large Manufacturing"
                          className="img-fluid"
                        />
                      </li>
                      <li className="block-title">MID-SIZE TECH COMPANY</li>
                      <li className="block-content">
                        See why this tech company chose Vigilant to provide
                        enhanced detection for their Microsoft applications to
                        keep their sensitive information secure.
                      </li>
                      <li className="block-read-more">
                        <p>
                          Read More <i className="block-chase-icon"></i>
                        </p>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a
                    href="https://sp1.sdcdn.app/temp/Vigilant-CyberDNA-Success-Story_Case Study.pdf"
                    target="__blank"
                  >
                    <ul className="block-link-card">
                      <li className="block-img">
                        <StaticImage
                          src="../images/landing/home_clients_03.jpg"
                          quality={95}
                          formats={["AUTO", "WEBP"]}
                          layout="fullWidth"
                          alt="Large Manufacturing"
                          className="img-fluid"
                        />
                      </li>
                      <li className="block-title">
                        LARGE PACKAGING SUPPLY CHAIN DISTRIBUTION
                      </li>
                      <li className="block-content">
                        Learn how Vigilant saved client hundreds of thousands of
                        dollars during ransomware atack using forensic data
                        collected by CyberDNA®.
                      </li>
                      <li className="block-read-more">
                        <p>
                          Read More <i className="block-chase-icon"></i>
                        </p>
                      </li>
                    </ul>
                  </a>
                </li>
              </ul>
            </div>
            <div className="section-col-2">
              <Link to="/client-stories/" className="nav-section--cta-btn mb-5">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    view all client stories
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
              <p className="p-quote">
                “LET YOUR GREAT OBJECT BE VICTORY.”
                <span className="p-quote--author">ART OF WAR</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-resources">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>RESOURCES</h3>
            </div>
            <div className="section-col-2">
              <ul className="resources-list">
                {resourcesList.map(data => {
                  return (
                    <FooterRecCard
                      altTxt={data.title}
                      title={data.title}
                      subTitle={data.subtitle}
                      recCat={data.cat}
                      LinkUrl={data.LinkUr}
                      key={uuidv4()}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
